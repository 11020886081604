import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/register',
    name: 'Register',
    meta:{
      title:"录入司机"
    },
    component: () => import('../views/register/Register.vue')
  },
  {
    path: '/register/index',
    name: 'RegisterIndex',
    meta:{
      title:"录入司机"
    },
    component: () => import('../views/register/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta:{
      title:"登录"
    },
    component: () => import('../views/login/Login.vue')
  },
  {
    path:'/completeInformation',
    name:'CompleteInformation',
    meta: {
      title: '补全信息'
    },
    component: () => import('../views/order/completeInformation.vue')
  },
  {
    path:'/vehiclePositioning',
    name:'VehiclePositioning',
    meta: {
      title: '查询车辆位置'
    },
    component: () => import('../views/order/VehiclePositioning.vue')
  },
  {
    path:'/orderlist',
    name:'OrderList',
    meta: {
      title: '订单列表'
    },
    component: () => import('../views/order/OrderList.vue')
  },
  {
    path:'/home',
    name:'Home',
    meta: {
      title: '首页'
    },
    component: () => import('../views/home/Home.vue')
  },
  {
    path:'/waybillList',
    name:'WaybillList',
    meta: {
      title: '运单列表'
    },
    component: () => import('../views/order/WaybillList.vue')
  },
  {
    path:'/waybillListdetail',
    name:'WaybillListdetail',
    meta: {
      title: '运单列表'
    },
    component: () => import('../views/order/WaybillListdetail.vue')
  },
  {
    path:'/regularWaybill',
    name:'RegularWaybill',
    meta: {
      title: '运力监控'
    },
    component: () => import('../views/order/RegularWaybill.vue')
  },
  {
    path:'/regularWaybillSearch',
    name:'RegularWaybillSearch',
    meta: {
      title: '运力监控'
    },
    component: () => import('../views/order/RegularWaybillSearch.vue')
  },
  {
    path:'/planWaybillSearch',
    name:'PlanWaybillSearch',
    meta: {
      title: '运力监控'
    },
    component: () => import('../views/order/PlanWaybillSearch.vue')
  },
  {
    path:'/waybillMain',
    name:'WaybillMain',
    meta: {
      title: '运力监控',
      KeepAlive:true
    },
    component: () => import('../views/order/WaybillMain.vue')
  },
  
  {
    path:'/planWaybill',
    name:'PlanWaybill',
    meta: {
      title: '运力监控',
      KeepAlive:true
    },
    component: () => import('../views/order/PlanWaybill.vue')
  },
  // 计划详情PlanDetails.vue
  {
    path:'/planDetails',
    name:'PlanDetails',
    meta: {
      title: '计划详情'
    },
    component: () => import('../views/Plan/PlanDetails.vue')
  },
  //运单详情waybillDetails.vue
  {
    path:'/waybillDetails',
    name:'waybillDetails',
    meta:{
      title:'运单详情'
    },
    component:() => import('../views/Plan/waybillDetails.vue')
  },
  //打点相关页面
  {
    path:'/main',
    name:'main',
    meta:{
      title:'功能列表'
    },
    component:() => import('../views/order/main.vue')
  },
  {
    path:'/edit',
    name:'edit',
    meta:{
      title:'信息录入'
    },
    component:() => import('../views/order/edit.vue')
  },
  {
    path:'/signAgreement',
    name:'signAgreement',
    meta:{
      title:'签署协议'
    },
    component:() => import('../views/order/signAgreement.vue')
  },
  {
    path:'/map',
    name:'map',
    meta:{
      title:'签署协议'
    },
    component:() => import('../views/order/map.vue')
  }
  
]

const router = new VueRouter({
  routes
})

export default router
