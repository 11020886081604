<template>
  <div id="app">
    <div id="nav">
    </div>
    <!-- <loading v-show="isLoading"></loading> -->
    <keep-alive>
      <router-view v-if="$route.meta.KeepAlive"  />
    </keep-alive>
    <router-view v-if="!$route.meta.KeepAlive" />
  </div>
</template>

<script>

import {mapState} from 'vuex'
import Loading from './components/Loading.vue'
export default {
  components: { Loading },
  data() {
    return {
    }
  },
  created() {
    let arr = [0,1,2,3]
    // delete(arr[1])
    this.$delete(arr,1)
    console.log(arr);
  },
  computed: {
    ...mapState(['isLoading'])
  }
}
</script>
<style lang="scss">
img {
  padding: 0;
  margin: 0;
}
div,p,span,h4,h3{
  margin: 0;
  padding: 0;
}
body,html {
  font-size: 0.37rem;
}

</style>
