import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import Api from './network/http'
import 'amfe-flexible/index.js'
import vueEsign from 'vue-esign'
import {
  cookie
} from '@/utils/utils'
Vue.use(Vant);
Vue.use(vueEsign)



import {
  Button,
  Select,
  Table,
  InfiniteScroll,
  TableColumn,
  Backtop,
  Col,
  Form,
  FormItem,
  DatePicker,
  Option,
  Input,
  TimePicker,
  Radio,
  RadioGroup,
  RadioButton,
} from 'element-ui';

Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Backtop)
Vue.use(Select)
Vue.use(Col)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(DatePicker)
Vue.use(Option)
Vue.use(Input)
Vue.use(TimePicker)
Vue.use(InfiniteScroll)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)

Vue.prototype.$cookie = cookie
Vue.prototype.$Api = Api
Vue.config.productionTip = false
//时间日期格式化
import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs;
// 日期格式化
Vue.filter('formatDate', (value, formate) => {
  if (value == '' || value == null) {
    return '--'
  } else {
    return dayjs(value).format(formate || 'YYYY-MM-DD HH:mm:ss')
  }
})

// 日期转时间戳
Vue.filter('formatDateUnix', (value, formate) => {
  if (value == '' || value == null) {
    return ''
  } else {
    return dayjs(value).unix()
  }
})

//保留两位小数
Vue.filter('toFixed', (value, num) => {
  value = Number(value)
  if (value == '' || value == null) {
    return '0'
  } else {
    return value.toFixed(num)
  }
})

//路由导航前置守卫 
router.beforeEach((to, from, next) => {
  const token = store.getters.getToken
  document.title = to.meta.title
  if (token) {
    next()
  } else if (to.name == 'Login' || to.name == 'Register') {
    next()
  } else {
    router.push('/login')
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')