'use strict'

/**
 * 校验手机号
 */
export const checkPhone = function (value) {
  let reg = /^1([3456789])\d{9}$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}
/**
 * 校验车牌号
 */
export const carNumber = function (value) {
  // let reg = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/;
  let reg = /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g;

  return reg.test(value);
}
/**
 * 校验身份证号
 */
export const IDCard = function (value) {
  let reg = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false
  }
}
/**
 * 获取/设置/清除cookie
 */
export const cookie = {
  set   : function (key, value, time) {
    // console.log(key,value,time);
    var date = new Date();
    var expiresDays = time;
    date.setTime(date.getTime() + expiresDays * 24 * 3600 * 1000);
    document.cookie = key + "=" + value + "; expires=" + date.toGMTString();//设置cookie
  },
  get   : function (key) {
    var getCookie = document.cookie.replace(/[ ]/g, "$"); //获取cookie，并且将获得的cookie格式化，去掉空格字符，换成$
    getCookie = getCookie.replace(/[;$]/g, ";");
    var arrCookie = getCookie.split(";") //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
    var tips; //声明变量tips
    for (var i = 0; i < arrCookie.length; i++) { //使用for循环查找cookie中的tips变量
      var arr = arrCookie[i].split("="); //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
      if (key == arr[0]) { //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
        tips = arr[1]; //将cookie的值赋给变量tips
        break; //终止for循环遍历
      }
    }
    if (tips) {
      tips = tips.replace(/[$]/g, " "); //还原空格字符串
    }
    return tips;
  },
  delete: function (key) {
    var date = new Date();
    date.setTime(date.getTime() - 10000);//将date设置为过去时间
    document.cookie = key + "=value;expires = " + date.toGMTString()
  }
}