import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading:false,
    token: (window.sessionStorage.getItem('token'))?(window.sessionStorage.getItem('token')):undefined,
    userInfo:(window.sessionStorage.getItem('userInfo'))?JSON.parse(window.sessionStorage.getItem('userInfo')):'',
    isHas:(window.sessionStorage.getItem('isHas'))?JSON.parse(window.sessionStorage.getItem('isHas')):''
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getUserInfo(state) {
      return state.userInfo
    },
    getIsHas(state) {
      return state.isHas
    }
  },
  mutations: {
    showLoading(state) {
      state.isLoading = true
    },
    hideLoading(state) {
      state.isLoading = false
    },
    login(state,payload) {
      // window.sessionStorage.setItem('token',JSON.stringify(payload.token) || '')
      window.sessionStorage.setItem('token',payload.token || '')
      window.sessionStorage.setItem('userInfo',JSON.stringify(payload.userInfo) || '')
      state.token = payload.token
      state.userInfo = payload.userInfo
    },
    setIsHas(state,payload) {
      console.log(payload);
      window.sessionStorage.setItem('isHas',payload.msg)
      state.isHas = payload.msg
    }
  },
  actions: {
    login({commit},payload) {
      commit('login',payload)
    },
    setIsHas({commit},payload) {
      console.log(payload);
      commit('setIsHas',payload)
    }
  },
  modules: {
    
  }
})
