import axios from "axios";
import store from "../store/index";
import { Toast } from "vant";
//打包发布时 放开axios.defaults,baseURL
// axios.defaults.baseURL = 'http://www.so56.net'

class Api {
  constructor() {
    axios.interceptors.request.use(
      (config) => {
        let token = store.state.token;
        if (token) {
          config.headers.token = token;
        }
        //在请求中加入token
        // store.commit('showLoading')

        // 司机信息模糊查询接口不需要loading显示
        if(config.url.indexOf('/carrier/changan/drivers/associate') == -1) {
          Toast.loading({
            message: "努力加载中…",
            forbidClick: true,
            duration: 0,
          });
        }
        return config;
      },
      (error) => {
        // store.commit('hideLoading')
        Toast.clear();
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        // store.commit('hideLoading')
        Toast.clear();
        if (response.status == 200) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      (error) => {
        console.log(error);
        Toast.clear();
        // store.commit('hideLoading')
        switch (error.response.status) {
          case 404:
            console.log(404);
            break;
        }
        return Promise.reject(error);
      }
    );
  }
  /**
   * 用户登录
   * @param {string} username    账号
   * @param {string} password    密码
   */
  async userLogin(params) {
    return axios.post(`/carrier/user/login`, params);
  }

  // /**
  //  * 获取订单列表
  //  * @param {string} status    状态
  //  */
  //   async getOrderList(params) {
  //     return axios.get(`/carrier/getOrderList`
  //     ,{params})
  //   }
  /**
   * 获取运单列表
   * @param {string} status  21待调度23在途24完成
   */

  async getWaybillList(params) {
    return axios.get(`/carrier/getWaybillList`, { params });
  }

  /**
   * 根据运单号获取运单列表
   * @param {string} waybillNo  运单号
   */
  async queryByWaybillNo(params) {
    return axios.get(`/carrier/queryByWaybillNo`, { params });
  }

  /**
   * 新增司机信息
   * @param {string}
   */
  async addDriverModify(params) {
    // return axios.post(`/carrier/driver/modify`,params)
    return axios.post(`/carrier/addDriver`, params);
  }
  /**
   * 删除计划司机
   * @param {string} planId   计划ID
   * @param {string} driverId   司机ID
   * @returns
   */
  async delDriverBindingPlan(params) {
    return axios.post(`/carrier/delDriverBindingPlan`, params);
  }
  /**
   * 删除运单司机
   * @param {string} orderId   计划ID
   * @param {string} waybillId   司机ID
   * @param {string} driverId   司机ID
   * @returns
   */
  async delOrderBindingWaybill(params) {
    return axios.post(`/carrier/delOrderBindingWaybill`, params);
  }
  /**
   * 结束运单
   * @param {string} waybillNo 运单号
   */
  // /endWaybillByWaybillNo
  async endWaybillByWaybillNo(params) {
    return axios.get(`/carrier/endWaybillByWaybillNo`, { params });
  }

  /**
   * 查询司机列表
   * @param {string} orders   订单号
   * @param {string} userId   用户ID
   * @param {string} length   每页数量
   * @param {string} start    起始索引
   */
  // /endWaybillByWaybillNo
  async driverSelectDriverList(params) {
    return axios.get(`/carrier/driver/selectDriverList`, { params });
  }
  // /carrier/createWaybill
  /**
   * 生成运单
   * @param {string} userId       用户ID
   * @param {string} driverId     司机ID
   * @param {string} realName     司机姓名
   * @param {string} phone        司机手机号
   * @param {string} carNo        车牌号
   * @param {string} orderId      订单id
   */
  // /endWaybillByWaybillNo
  async createWaybill(params) {
    return axios.get(`/carrier/createWaybill`, { params });
  }

  /**
 * 上传图片
 * @param {string}

 */
  async uploadPicture(phone, params) {
    return axios.post(`/carrier/driver/uploadPicture/${phone}`, params);
  }

  /**
   * 获取当前企业下的车队信息
   * @param {string} userId   用户id
   */
  async queryCarGroup(id) {
    return axios.get(`/carrier/driver/queryCarGroup`, {
      params: {
        userId: id,
      },
    });
  }
  /**
   * 获取品牌型号数据信息
   * @param {string} malName   品牌型号名称
   */
  async queryCarModel(params) {
    return axios.get(`/carrier/driver/queryCarModel`, { params });
  }

  /**
   * 获取订单列表
   * @param {string} userId   当前登录用户的deptId
   */
  async getOrderList(params) {
    return axios.get(`/carrier/getOrderList`, { params });
  }

  /**
   * 根据单号查询订单
   * @param {string} orderNo   订单号或者计划单号
   */
  async queryByOrderNo(params) {
    return axios.get(`/carrier/queryByOrderNo`, { params });
  }

  /**
   * 计划订单派单
   * @param {string} userId     当前登录用户的deptId
   * @param {string} planId     计划订单id
   * @param {string} driverId   当前司机的id
   * @param {string} id         当前登录用户的Id
   */
  async createTransportPlan(params) {
    return axios.post(`/carrier/createTransportPlan`, params);
  }

  /**
   * 判断运力是否有计划
   * @param {string} userId     当前登录用户的deptId
   * @param {string} planId     计划订单id
   * @param {string} driverId   当前司机的id
   * @param {string} id         当前登录用户的Id
   */
  async isHasPlan(params) {
    return axios.get(`/carrier/isHasPlan`, { params });
  }

  /**
   * 运力监控，没有计划的承运商
   * @param {string} userId     当前登录用户的deptId
   *
   */
  async waybillList(params) {
    return axios.get(`/carrier/waybillList`, { params });
  }

  /**
   * 根据订单号查询
   * @param {string} orderNo     订单号
   *
   */
  async queryInfoByOrderNo(params) {
    return axios.get(`/carrier/queryInfoByOrderNo`, { params });
  }

  // /carrier/transport
  /**
   * 运力监控，有计划的承运商
   * @param {string} userId     当前登录用户的deptId
   *
   */
  async transport(params) {
    return axios.get(`/carrier/transport`, { params });
  }
  /**
   * 获取订单计划列表
   * @param {string} userId     用户deptId
   *
   */
  async planOrderList(params) {
    return axios.get(`/carrier/planOrderList`, { params });
  }

  /**
   * 获取订单计划详情
   * @param {string} id              用户deptId
   * @param {string} transportNo     运输计划号
   *
   */
  async planOrderDetail(params) {
    return axios.get(`/carrier/planOrderDetail`, { params });
  }
  //获取订单详情
  async getWaybillDetail(params) {
    return axios.get("/carrier/waybillDetail", { params });
  }
  // 根据手机号查询司机
  /**
   *
   * @param {string} phone  手机号
   * @returns
   */
  async selectDriverByPhone(params) {
    return axios.post("/carrier/selectDriverByPhone", params);
  }
  /**
   * 打点获取运单状态
   * @param {string} userId     当前登录用户的deptId
   *
   */
  async selectStatus(params) {
    return axios.get(`/carrier/selectStatus`, { params });
  }
  /**
   * 打点获取订单状态
   * @param {string} userId     当前登录用户的deptId
   *
   */
  async selectStatusByOrderId(params) {
    return axios.get(`/carrier/selectStatusByOrderId`, { params });
  }
  /**
   * 签名验证接口
   * @param {string} userId     当前登录用户的deptId
   *
   */
  async getDriverInfo(params) {
    return axios.get(`/carrier/driver/getDriverInfo`, { params });
  }
  /**
   * 提交签名接口
   * @param {string} userId     当前登录用户的deptId
   *
   */
  async addConfirmationAgreement(params) {
    return axios.post(`/carrier/waybill/addConfirmationAgreement`, params);
  }
  /**
   * 提交打点接口
   * @param {string} userId     当前登录用户的deptId
   *
   */
  async forward(params) {
    return axios.post(`/carrier/carrierForward`, params);
  }
  /**
 * 上传图片
 * @param {string}
 */
  async uploadPictures(phone, params) {
    return axios.post(`/carrier/uploadPicture/${phone}`, params);
  }
  /**
 * 根据车牌号查询车辆轨迹-并标点
 * @param {string}
 */
  async realTimeLocWcf(params) {
    return axios.get(`/carrier/realTimeLocWcf?carNo=${params}`);
  }


  /**
   * 从长安抓取到的待补全的司机信息
   * @param {string}
   */
  async draftListApi(params) {
    return axios.get(`/carrier/changan/waybill/draft/list`, { params });
  }
  /**
   * 根据车牌号模糊查询司机信息
   * @param {string}
   */
  async associateApi(params) {
    return axios.get(`/carrier/changan/drivers/associate?carNo=${params}`);
  }
  /**
   * 待补全司机进行提交
   * @param {string}
   */
  async completionWaybillApi(params) {
    return axios.post(`/carrier/changan/waybill/completionWaybill`, params);
  }
}

export default new Api();
